export default class Header {
  constructor() {
    let header = document.querySelector('.header');

    if (header.length < 1) return

    this.init(header)
  }

  init(header) {
    this.initEvents(header);
  }

  initEvents(header) {
    document.body.addEventListener('scroll', () => this.toggleScrollHeader(header), false); // For firefox
    window.addEventListener('scroll', () => this.toggleScrollHeader(header), false); // For chrome

    let inputMenu = document.querySelector('.header__burger');
    inputMenu.addEventListener('click', () => this.toggleMenu(header), false);
  }

  /**
   * Add and remove class on header element
   * On scroll on window
   */
  toggleScrollHeader(header) {
    let windowY = window.scrollY || document.body.scrollTop || document.documentElement.scrollTop;
    if (windowY > 0) {
      // Scrolling UP
      header.classList.add('is-scrolling');
    } else {
      // Scrolling DOWN
      header.classList.remove('is-scrolling');
    }
  }

  /**
   * Add and remove overflow on body and html element
   * To prevent scroll on page when menu is opened
   */
  toggleMenu(header) {
    if (!header.classList.contains('is-opened')) {
      header.classList.add('is-opened');
      document.body.style.overflow = 'hidden';
      document.documentElement.style.overflow = 'hidden';
    } else {
      header.classList.remove('is-opened');
      document.body.style.overflow = '';
      document.documentElement.style.overflow = '';
    }
  }
}
