/**
 * Class Faq.
 */
export default class Faq {

  constructor(elements) {
    const faqCards = document.querySelectorAll(elements);

    if (!faqCards.length) return;

    faqCards.forEach(faqCard => {
      this.addListener(faqCards, faqCard);
    });
  }

  /**
   * Add event listener on each question.
   *
   * @param  {object} faqCards all the question cards of the FAQ
   * @param  {object} faqCard each question card
   */
  addListener(faqCards, faqCard) {
    // Click event on a question card
    faqCard.addEventListener('click', () => {
      // If it's not opened
      if (!faqCard.classList.contains('is-opened')) {
        // We check for all question cards to close them
        Faq.handleClose(faqCards);
        // Then we open it
        faqCard.classList.add('is-opened');
      } else {
        // Else we close it
        faqCard.classList.remove('is-opened');
      }
    });
  }

  /**
   * Close all question cards
   *
   * @param  {object} faqCards
   */
  static handleClose(faqCards) {
    faqCards.forEach(faqCard => {
      if (faqCard.classList.contains('is-opened')) {
        faqCard.classList.remove('is-opened');
      }
    });
  }
}
