module.exports = {
  getOffset(el) {
    var rect = el.getBoundingClientRect(),
      scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
      scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    return { top: rect.top + scrollTop, left: rect.left + scrollLeft };
  },

  jump(anchor) {
    var url = location.href; //Save down the URL without hash.
    location.href = "#" + anchor;  //Go to the target element.
    history.replaceState(null, null, url);  //Don't like hashes. Changing it back.
  },
};