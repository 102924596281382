/**
* Class Forms.
*/
export default class Forms {
  constructor(elements) {
    const elmts = document.querySelectorAll(elements);

    if (!elmts.length) return;

    elmts.forEach(form => {
      this.inputsListener(form);
      this.handleSubmitButton(form);
    });
  }

  /**
  * Add event listener on form
  *
  * @param  {object} form
  */
  inputsListener(form) {
    const inputs = form.querySelectorAll('.input__field');

    // For each inputs
    inputs.forEach(input => {
      // Add class 'is-fill' on focus
      input.addEventListener('focus', event => {
        event.target.classList.add('is-fill');
        event.target.parentElement.classList.add('is-fill');
      });
      // Remove class 'is-fill' on blur
      input.addEventListener('blur', event => {
        if (event.target.value === '') {
          event.target.classList.remove('is-fill');
          event.target.parentElement.classList.remove('is-fill');
        }
      });
    });
  }

  /**
  * Check if the form is submittable
  *
  * @param  {object} inputs inputs of the form
  */
  isSubmittable(inputs) {
    let isSubmittable = false;

    // for each input of the form
    for (let i = 0; i < inputs.length; i++) {
      // Check if at least one input value is empty
      if (inputs[i].value.length == 0) {
        isSubmittable = false;
        break;
      } else {
        isSubmittable = true;
      }
    }

    return isSubmittable;
  }

  /**
  * Handle submit button disabling
  *
  * @param  {object} form
  */
  handleSubmitButton(form) {
    const inputs = form.querySelectorAll('.input__field');
    const submitButton = form.querySelector('.cta__link');
    const cta = submitButton.closest('.cta');

    submitButton.disabled = true;

    document.addEventListener('wpcf7mailsent', () => {
      Array.from(inputs).forEach(input => {
        input.classList.remove('is-fill');
        input.parentElement.classList.remove('is-fill');
      });
    });

    // For each input of the form
    Array.from(inputs).forEach(input => {
      // Check if the input value has changed
      input.addEventListener('input', () => {
        // If all inputs are fullfilled
        if (this.isSubmittable(inputs)) {
          // We enable submit button
          submitButton.disabled = false;
          cta.classList.remove('is-disabled');
        } else {
          // Else we disabled submit button
          submitButton.disabled = true;
          cta.classList.add('is-disabled');
        }
      });
    });
  }
}
