import Chart from 'chart.js';

export default class Stats {
  constructor(chart) {
    const charts = document.querySelectorAll(chart);

    if (!charts.length) return;

    // Init charts.
    this.init(charts);
  }

  init(charts) {
    [...charts].map(chart => {
      this.initChart(chart);
    })
  }

  initChart(chart) {
    let ctx = chart.id;
    let options = JSON.parse(chart.dataset.options);

    let backgroundColors = [];
    options.backgroundColor.map(backgroundColor => {
      backgroundColors.push('rgba(' + backgroundColor + ')')
    });

    let borderColors = [];
    options.borderColor.map(borderColor => {
      borderColors.push(borderColor)
    });


    new Chart(ctx, {
      type: chart.dataset.type,
      data: {
        labels: JSON.parse(chart.dataset.labels),
        datasets: [{
          label: false,
          data: JSON.parse(chart.dataset.data),
          backgroundColor: backgroundColors,
          borderColor: borderColors,
          borderWidth: 1
        }]
      },
      options: {
        legend: options.legend ? options.legend : false,
        tooltips: {
          enabled: false,
          custom: function (tooltipModel) {
            // Tooltip Element
            var tooltipEl = document.getElementById('chartjs-tooltip');

            // Create element on first render
            if (!tooltipEl) {
              tooltipEl = document.createElement('div');
              tooltipEl.id = 'chartjs-tooltip';
              document.querySelector('.chart__container').appendChild(tooltipEl);
            }

            // Hide if no tooltip
            if (tooltipModel.opacity === 0) {
              tooltipEl.style.opacity = 0;
              return;
            }

            // Set caret Position
            tooltipEl.classList.remove('above', 'below', 'no-transform');
            if (tooltipModel.yAlign) {
              tooltipEl.classList.add(tooltipModel.yAlign);
            } else {
              tooltipEl.classList.add('no-transform');
            }

            function getBody(bodyItem) {
              return bodyItem.lines;
            }

            // Set Text
            if (tooltipModel.body) {
              var titleLines = tooltipModel.title || [];
              var bodyLines = tooltipModel.body.map(getBody);

              var innerHtml = '<div class="chart__tooltip-content">';

              if (chart.dataset.tooltip && bodyLines[0][0].includes('Nombre de projets bénéficiant d\'un accompagnement')) {
                let tooltipContents = JSON.parse(chart.dataset.tooltip);
                tooltipContents.map(tooltipContent => {
                  innerHtml += '<p>' + tooltipContent + ' </p>'
                })
              } else {
                titleLines.forEach(function (title) {
                  innerHtml += '<span>' + title + ' </span>';
                  innerHtml += ' : ';
                });

                bodyLines.forEach(function (body, i) {
                  innerHtml += '<span> ' + body + '</span>';
                });
              }

              innerHtml += '</div>';

              tooltipEl.innerHTML = innerHtml;
            }

            // `this` will be the overall tooltip
            var position = this._chart.canvas.getBoundingClientRect();

            // Display, position, and set styles for font
            tooltipEl.style.opacity = 1;
            tooltipEl.style.backgroundColor = '#FFFFFF';
            tooltipEl.style.color = '#0B184D;';
            tooltipEl.style.left = position.left + tooltipModel.caretX - 50 + 'px';
            tooltipEl.style.top = position.top + tooltipModel.caretY + 60 + 'px';
          }
        },
        scales: {
          xAxes: [{
            display: options.axes === false ? false : true,
          }],
          yAxes: [{
            display: options.axes === false ? false : true,
            ticks: {
              beginAtZero: true
            }
          }]
        }
      }
    });
  }
}
