/**
 * Class Video.
 *
 * @desc:
 */
export default class Video {

    /**
     * iFrame template.
     *
     * @param params
     * @returns {string}
     * @private
     */
    _iframe(params) {
        const defaultParams = {
            vid: null,
            type: null,
            url: null,
            filename: 'hqdefault.jpg',
            attributes: []
        };

        params = Object.assign(defaultParams, params);
        return `<iframe src="${params.url}?autoplay=1" 
            class="card-video__iframe"
            frameborder="0" 
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
            allowfullscreen></iframe>`;
    }

    /**
     * Constructor.
     *
     * @param element
     */
    constructor(element) {
        const videos = document.querySelectorAll(element);

        [...videos].map(video => {
            video.addEventListener('click', e => {
                e.preventDefault();
                this.loadVideo(video);
            });
        });
    }

    /**
     * Load video.
     *
     * @param video
     */
    loadVideo(video) {
        video.classList.add('loaded');
        video.innerHTML = this._iframe(video.dataset);
    }

}
