/**
* Class Tabs.
*/

import Utils from './utils';

export default class Tabs {

  constructor(tabs) {
    // To check if user is on template page program
    const programPage = document.querySelector('body.program');
    const faqPage = document.querySelector('body.faq');
    if (!faqPage && !programPage) return;

    this.isClicked = false; // Click initialization

    const blockTabs = document.querySelectorAll(tabs); // NodeList of block-tabs

    if (!blockTabs.length) return;

    [...blockTabs].map( blockTab => {
      this.handleListeners(blockTab); // Div block-tabs
    });

    const tabAnchor = document.querySelector('.slider');
    const eligibility = document.querySelector('.eligibility');
    
    if (tabAnchor) {
      // Difference between top window and tabAnchor - header height
      let scrollingBackTop = tabAnchor.getBoundingClientRect().top - 88;

      // For firefox
      document.body.addEventListener('scroll', () => this.toggleScrollSlider(tabAnchor, scrollingBackTop, eligibility), false);

      // For chrome
      window.addEventListener('scroll', () => this.toggleScrollSlider(tabAnchor, scrollingBackTop, eligibility), false);
    }
  }

  /**
  * Add event listener on tab.
  *
  * @param blockTab
  */
  handleListeners(blockTab) {
    const tabs = blockTab.querySelectorAll('.block-tabs__item'); // NodeList of tabs
    const anchors = document.querySelectorAll('.second-title'); // NodeList of anchors
    
    // For each tabs items, add 'active' class on click
    [...tabs].map( tab => {
      tab.addEventListener('click', () => {
        this.isClicked = true;
      });
    });

    // For each anchors, call handleActiveTab() on scroll
    if (this.isClicked === false) {
      [...anchors].map(anchor => {
        window.addEventListener('scroll', () => {
          this.handleActiveTab(anchor, tabs, 88);
        });
      });
    }
  }

  /**
   * Manage block-tabs on scroll
   * 
   * @param {NodeList} anchor
   * @param {NodeList} tabs
   * @param {Number} offset
   */
  handleActiveTab(anchor, tabs, offset) {
    const anchorPosition = Utils.getOffset(anchor).top;

    // Number of scroll pixel from top of window
    const windowScrollY =
    window.scrollY ||
    document.body.scrollTop ||
    document.documentElement.scrollTop;
  
    if (windowScrollY >= anchorPosition - offset) {
      [...tabs].map(tab => {
        // To remove # from href
        const linkHref = tab.getAttribute('href').slice(1);
        const anchorID = anchor.getAttribute('id');

        // If block-tabs attributes and titles attributes are the same
        if (linkHref === anchorID) {
          tab.classList.add('active');
        } else {
          tab.classList.remove('active');
        }
      });
    }
  }

  /**
   * Manage sticky block-tabs on scroll
   * 
   * @param {NodeList} tabAnchor
   * @param {Number} scrollingBackTop
   * @param {NodeList} eligibility
   */
  toggleScrollSlider(tabAnchor, scrollingBackTop, eligibility) {
    let scrollingTop = tabAnchor.getBoundingClientRect().top;
    let windowY = window.scrollY || document.body.scrollTop || document.documentElement.scrollTop;

    if (scrollingTop <= 88 && windowY >= scrollingBackTop) {
      // Scrolling UP
      tabAnchor.classList.add('is-sticky');
      if (eligibility) {
        eligibility.classList.add('is-sticky');
      }
    } else {
      // Scrolling DOWN
      tabAnchor.classList.remove('is-sticky');
      if (eligibility) {
        eligibility.classList.remove('is-sticky');
      }
    }
  }

  /**
  * Reset attribute "class".
  *
  * @param tabs
  */
  static reset(tabs) {
    [...tabs].map( tab => {
      if (tab.classList.contains('active')) {
        tab.classList.remove('active');
      }
    });
  }

  /**
  * Set active item.
  *
  * @param blockTabs
  * @param index
  */
  static setActiveItem(blockTabs, index) {
    const tabs    = blockTabs.querySelectorAll('.block-tabs__item');
    const current = blockTabs.querySelector(`[data-glide-dir="=${index}"]`);
    Tabs.reset(tabs);
    current.classList.add('active');
  }
}