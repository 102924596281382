export default class Eligibility {
  constructor(element, maxStepNumber) {
    this.eligibility = document.querySelector(element);
    this.maxStepNumber = maxStepNumber;

    if (!this.eligibility) return null;

    this.eligibilitySteps = this.eligibility.querySelectorAll('.eligibility__step');
    this.eligibiligyTags = this.eligibility.querySelectorAll('.checkbox');
    this.eligibiligyRadios = this.eligibility.querySelectorAll('.radio');
    this.eligibiligyTexts = this.eligibility.querySelectorAll('.input__field');
    this.eligibiligyCurrentStep = this.eligibility.querySelector('.eligibility__step:not(.is-next)');

    this.currentStep = this.eligibility.querySelector('.eligibility__counter--current');
    this.maxStep = this.eligibility.querySelector('.eligibility__counter--max');
    this.init();
  }

  init() {
    // To initialize step
    this.currentStep.textContent = 1;
    this.maxStep.textContent = '/ ' + this.maxStepNumber;

    this.eligibilitySteps.forEach(eligibilityStep => {
      if (!eligibilityStep.querySelector('.eligibility__button--next').classList.contains('cta--account')) {
        eligibilityStep.querySelector('.eligibility__button--next').addEventListener('click', (e) => this.handleNextStep(e, eligibilityStep), false);
      }
      eligibilityStep.querySelector('.eligibility__button--prev') && eligibilityStep.querySelector('.eligibility__button--prev').addEventListener('click', (e) => this.handlePrevStep(e, eligibilityStep), false);
    });

    this.eligibiligyTags.forEach(eligibiligyTag => {
      eligibiligyTag.addEventListener('click', () => this.handleTags(eligibiligyTag), false);
    });

    this.eligibiligyRadios.forEach(eligibiligyRadio => {
      eligibiligyRadio.addEventListener('click', () => this.handleRadios(eligibiligyRadio), false);
    });

    this.eligibiligyTexts.forEach(eligibiligyText => {
      eligibiligyText.addEventListener('keyup', () => this.handleTexts(eligibiligyText), false);
    });
  }

  _getAmount() {
    return document.querySelector('input[name="amount"]').value;
  }

  _getShelterModel() {
    return document.querySelector('.radio--shelter_type.is-checked').querySelector('input').value;
  }

  _getShelterNumber() {
    return document.querySelector('input[name="shelter_number"]').value;
  }

  handleNextStep(e, eligibilityStep) {
    e.preventDefault();
    eligibilityStep.classList.add('is-prev');
    eligibilityStep.classList.remove('is-current');

    if (eligibilityStep.nextElementSibling) {
      eligibilityStep.nextElementSibling.classList.add('is-current');
      eligibilityStep.nextElementSibling.classList.remove('is-next');
    } else {
      console.log('Pas d\'étape suivante');
    }

    this.currentStep.textContent++;

    if (parseInt(this.currentStep.textContent) === this.maxStepNumber) {
      const amount = this._getAmount();
      const shelterModel = this._getShelterModel();
      const shelterNumber = this._getShelterNumber();
      document.querySelector('.eligibility__bonus span').innerHTML = this.calculProjectBonus(amount, shelterModel, shelterNumber) + ' €';
    }
  }

  handlePrevStep(e, eligibilityStep) {
    e.preventDefault();
    eligibilityStep.classList.add('is-next');
    eligibilityStep.classList.remove('is-current');

    eligibilityStep.previousElementSibling.classList.add('is-current');
    eligibilityStep.previousElementSibling.classList.remove('is-prev');

    this.currentStep.textContent--;
  }

  checkNextStep(step) {
    let nextLink = step.querySelector('.eligibility__button--next');
    if (step.querySelector('.is-checked')) {
      nextLink.classList.remove('is-disabled');
    } else {
      nextLink.classList.add('is-disabled');
    }
  }

  handleTags(tag) {
    if(tag.classList.contains('is-checked')) {
      tag.classList.remove('is-checked');
    } else {
      tag.classList.add('is-checked');
    }

    this.checkNextStep(this.eligibility.querySelector('.eligibility__step.is-current'));
  }

  handleRadios(radio) {
    if(radio.classList.contains('is-checked')) {
      radio.classList.remove('is-checked');
    } else {
      this.eligibiligyRadios.forEach(eligibiligyRadio => {
        eligibiligyRadio.classList.remove('is-checked');
      });
      radio.classList.add('is-checked');
    }

    this.checkNextStep(this.eligibility.querySelector('.eligibility__step.is-current'));
  }

  handleTexts(text) {
    if (parseInt(text.value) < 1 || !text.value) {
      text.classList.remove('is-checked');
    } else {
      text.classList.add('is-checked');
    }

    this.checkNextStep(this.eligibility.querySelector('.eligibility__step.is-current'));

    if (text.name === "amount") {
      const amount = this._getAmount();
      const shelterModel = this._getShelterModel();
      const shelterNumber = this._getShelterNumber();
      document.querySelector('.eligibility__bonus span').innerHTML = this.calculProjectBonus(amount, shelterModel, shelterNumber) + ' €';
    }
  }

  calculProjectBonus(amount, shelterModel, shelterNumber) {
    let shelterCap;
    let bonus = (amount * 60) / 100;
    bonus = bonus.toFixed(2);

    switch (shelterModel) {
      case 'secure_deposit':
        shelterCap = 2000;
        break;
      case 'free_access_outdoor':
        shelterCap = 700;
        break;
      case 'existing_room':
        shelterCap = 200;
        break;
      case 'uncovered':
        shelterCap = 150;
        break;
    }

    const bonusCap = shelterCap * shelterNumber;

    if(!amount) {
      bonus = bonusCap;
    } else {
      if (bonus > bonusCap) {
        bonus = bonusCap;
      }
    }

    return bonus;
  }
}
