/**
* Class Modal.
*/
export default class Modal {

  constructor(options) {
    this.triggers = document.querySelectorAll(options.triggers);
    this.modals = document.querySelectorAll(options.modals);

    if (!this.triggers.length) return;

    [...this.triggers].map(trigger => {
      trigger.addEventListener('click', () => this.handleModal(trigger), false);
    });

    [...this.modals].map(modal => {
      modal.addEventListener('click', (e) => this.closeModal(e, modal), false);
    });
  }

  /**
   * Open modal on click on trigger
   *
   * @param {Object} trigger trigger's modal
   */
  handleModal(trigger) {
    const modalClass = trigger.getAttribute('data-modal');

    // For each modal
    this.modals.forEach((modal) => {
      // Hide all modals
      modal.classList.add('is-hidden');
      // Open the triggered one
      if (modal.getAttribute('data-modal') === modalClass) {
        modal.classList.remove('is-hidden');
        // Prevent page from scrolling
        document.body.style.overflow = 'hidden';
        document.documentElement.style.overflow = 'hidden';
      }
    });
  }

  /**
   * Close modal on click outside it
   *
   * @param {Object} e event
   * @param {object} modal modal
   */
  closeModal(e, modal) {
    if (e.target.classList.contains('modal') && !modal.classList.contains('is-hidden')) {
      modal.classList.add('is-hidden');
      // Re enable scroll on page
      document.body.style.overflow = '';
      document.documentElement.style.overflow = '';
    }
  }
}
