import Glide from "@glidejs/glide";
import Tabs from './tabs';

let _sliders = [];

/**
 * Class Sliders.
 */
export default class Sliders {

    constructor(elements) {
        const elmts = document.querySelectorAll(elements);

        if (!elmts.length) return;

        // Init sliders.
        this.initSliders(elmts);
        // Mount sliders.
        this.mountSliders();
    }

    /**
     * Init sliders.
     *
     * @param sliders
     */
    initSliders(sliders) {
        [...sliders].map( slider => {
            _sliders.push(new Glide(slider, {
                type: 'slider',
                startAt: 0,
                perView: 1,
                focusAt: 'center',
                gap: 0,
                peek: 215,
                breakpoints: {
                    768: {
                        peek: 20,
                        focusAt: 0,
                    }
                }
            }));
        });
    }

    /**
     * Mount each slider.
     */
    mountSliders() {
        [..._sliders].map( slider => {
            // Event mount.
            slider.mount();
            // Event run.
            this.eventRun(slider);
        });
    }

    /**
     * Behavior on run event.
     */
    eventRun(slider) {
        slider.on('run', () => {
            this.updateTabs(slider, slider.index);
            this.displayControls(slider, slider.index);
        });
    }

    /**
     * Update tabs.
     *
     * @param slider
     * @param index
     */
    updateTabs(slider, index) {
        const scope = slider.selector;
        const tabs  = scope.querySelector('.block-tabs');
        Tabs.setActiveItem(tabs, index);
    }

    /**
     * Display controls relatively to the index.
     *
     * @param slider
     * @param index
     */
    displayControls(slider, index) {
        const scope    = slider.selector;
        const length   = scope.querySelectorAll('.block-slider__slide').length;
        const controls = scope.querySelector('.block-slider__controls');
        const prev     = controls.querySelector('.block-slider__control--prev');
        const next     = controls.querySelector('.block-slider__control--next');

        if (!index) {
            prev.classList.add('isHide');
        } else if(index === (length - 1)) {
            prev.classList.remove('isHide');
            next.classList.add('isHide');
        } else {
            prev.classList.remove('isHide');
            next.classList.remove('isHide');
        }
    }
}
