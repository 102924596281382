require('../icons/svgxuse.js');

import anime from 'animejs/lib/anime.es.js';
import AOS from 'aos';
import lozad from 'lozad';
import Header from './header';
import Video from './videos';
import Sliders from './sliders';
import Tabs from "./tabs";
import Eligibility from './eligibility';
import Faq from './faq';
import Forms from './forms';
import Modal from './modal';
import Stats from './stats';

const App = {
  init() {
    // test for touch events support and if not supported, attach .no-touch class to the HTML tag.
    if (!("ontouchstart" in document.documentElement)) {
      document.documentElement.className += " no-touch";
    }

    new Header();
    new Video('.card-video--video');
    new Eligibility('.eligibility', 5);
    new Sliders('.block-slider');
    new Tabs('.block-tabs');
    new Faq('.card-faq');
    new Forms('.form, .wpcf7-form');
    new Modal({triggers: '.trigger-modal', modals: '.modal'});
    new Stats('.chart');


    let browser = window.navigator.userAgent;
    let ie = browser.indexOf("MSIE ");

    if (ie > 0 || !!window.MSInputMethodContext && !!document.documentMode) {
      document.documentElement.className += " ie";
    }

    if(document.querySelector('.home')) {
      AOS.init({
        disable: 'mobile'
      });
      App.handleBikeAnimation();
      App.handleHomeParallax();
    }

    // Handle Banner
    let banner = document.querySelector('.banner');
    let menu = document.querySelector('.header__menu');
    if (banner) {
      let bannerHeight = banner.clientHeight;
      document.body.style.paddingTop = bannerHeight + 'px';
      document.querySelector('.banner__close').addEventListener('click', () => {
        document.body.classList.remove('has-banner');
        document.body.style.paddingTop = '';
        menu.style.top = '';
      })
      if(window.innerWidth < 1200) {
        menu.style.top = bannerHeight + 88 + 'px';
      }
    }
  },

  handleBikeAnimation() {
    let myPath = anime.path('.anime__path--bike path');

    let animation = anime({
      targets: '.anime__element',
      translateX: myPath('x'),
      translateY: myPath('y'),
      rotate: myPath('angle'),
      easing: 'linear',
      duration: 500,
      loop: false,
      autoplay: false
    });

    let h = document.documentElement,
        b = document.body,
        st = 'scrollTop',
        sh = 'scrollHeight';

    document.addEventListener('scroll', () => {
      let percent = (h[st]||b[st]) / ((h[sh]||b[sh]) - h.clientHeight) * 100;

      animation.seek(animation.duration * (percent/100));
    });
  },

  handleHomeParallax() {
    const squareImage = document.querySelector('.hero__root-square');
    const cyclistImage = document.querySelector('.hero__bicycle');

    const h = document.documentElement,
        b = document.body,
        st = 'scrollTop',
        sh = 'scrollHeight';

    document.addEventListener('scroll', () => {
      let percent = (h[st] || b[st]) / ((h[sh] || b[sh]) - h.clientHeight) * 100;
      let translateValue = (-0.1 * (percent * 100));
      if (translateValue > -150) {
        squareImage.style.transform = 'translateX(' + translateValue + 'px)';
        cyclistImage.style.transform = 'translateX(' + translateValue + 'px)';
      }
    });
  }
}

document.addEventListener('DOMContentLoaded', () => {
  var observer = lozad();
  observer.observe();

  const firstImage = document.querySelector('.lozad-first')
  if (firstImage) {
    observer.triggerLoad(firstImage);
  }

  App.init();
});

